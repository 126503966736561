@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir';
  src: url('./assets/AvenirLTProRoman.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/AvenirLTProHeavy.otf') format('opentype');
  font-weight: 700;
  font-style: bold;
}

@layer base {
  html {
    font-family: "Avenir", system-ui, sans-serif;
  }
}